import StreamClient from 'rikerio-client.ts'
import Vue from 'vue'

export default (v:typeof Vue): void => {
  const devMode = process.env.NODE_ENV === 'development'
  const locPort = window.location.port || '80'
  const locHost = window.location.hostname
  const host = devMode ? process.env.VUE_APP_DEV_SERVER : locHost
  let port : number | undefined = undefined
  
  if (devMode) {
    port = parseInt(process.env.VUE_APP_DEV_PORT)
  } else {
    if (locPort === '') {
      port = parseInt(locPort)
    } else {
      port = undefined
    }
  }

  console.log(window.location, locHost, locPort)
  console.log(process.env.NODE_ENV, devMode, host, port)
  const client = new StreamClient({
    scheme: location.protocol === 'https:' ? 'https' : 'http',
    host,
    port,
    localStorage: window.localStorage
  })

  console.log(client.host, client.wsHost)

  v.prototype.$stream = {
    client
  }
}
