import Vue from 'vue'
import App from './pages/apps/Apps.vue'
import vuetify from './plugins/vuetify'
import router from './router/apps'
import setupRikerIOClient from './setup'

Vue.config.productionTip = false
Vue.config.devtools = true

setupRikerIOClient(Vue)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
