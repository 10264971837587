
















import { Component, Vue } from 'vue-property-decorator'
import { QueryResponse, 
  StarterService,
  CommonResponse,
  Starter,
  ApplicationService,
  Application } from 'rikerio-client.ts'

interface ExtendedStarter extends Starter {
  app?: Application
}

@Component
export default class Home extends Vue {

  starterQuery: QueryResponse<ExtendedStarter> = {
    meta: { 
      filter: '', 
      page: 1, 
      page_count: 1, 
      page_size: 10, 
      sort_by: '', 
      sort_order: 'desc', 
      total_count: 0 
    }, 
    results: [ ] 
  }

  execute(starter: CommonResponse<ExtendedStarter>): void {
    const url = '/app/1/' + starter.data.id + '/'
    if (process.env.NODE_ENV === 'development') {
      window.open('http://' + process.env.VUE_APP_DEV_SERVER + url)
      return
    }
    window.location.href = '/app/1/' + starter.data.id + '/'
  }

  async query(): Promise<void> {
    try {

      const applicationService = new ApplicationService(this.$stream.client)
      const appQuery = await applicationService.query('', 1, -1).execute()
      const appMap = new Map()
      appQuery.results.forEach((app) => { appMap.set(app.data.id, app.data) })

      const starterService = new StarterService(this.$stream.client)
      this.starterQuery = await starterService.query('', 1, -1).execute()
      this.starterQuery.results.forEach((s) => { s.data.app = appMap.get(s.data.app_id) })
      console.log(this.starterQuery)
    } catch (e) {
      console.error(e)
    }

  }

  async created(): Promise<void> {
    this.query()
  }

}
