import { Component, Vue } from 'vue-property-decorator'
import {
  VersionResponse,
  SettingService,
  AuthenticateResponse } from 'rikerio-client.ts'

const devMode = process.env.NODE_ENV === 'development'

@Component
export default class App extends Vue {
  showNav = false
  applicationTitle = 'Untitled'
  applicationProject = 'Project'
  authenticated = false
  version: string = process.env.VUE_APP_VERSION
  serverVersion: VersionResponse = { 
    version: { 
      major: 0, 
      minor: 0, 
      patch: 0 
    } 
  }
  auth: AuthenticateResponse | null = null

  redirect (url: string): void {
    window.location.replace(url)
  }

  gotoAdminApp (): void {
    this.redirect(devMode ? '/admin' : '/www/admin')
  }

  get serverVersionString (): string {
    const v = this.serverVersion.version
    return `${v.major}.${v.minor}.${v.patch}`
  }

  async logout (): Promise<void> {
    const url = devMode ? '/login' : '/www/login'
    try {
      await this.$stream.client.logout()
      this.redirect(url)
    } catch (e) {
      console.error(e)
    }
  }

  async created (): Promise<void> {
    const service = new SettingService(this.$stream.client)

    try {
      this.auth = await this.$stream.client.reauthenticate()
      this.authenticated = true
    } catch (e) {
      this.redirect(devMode  ? '/login?target=/apps' : '/www/login?target=/www/apps')
    }

    try {
      this.serverVersion = (await this.$stream.client.getServerVersion())
      const titleResponse = (await service.getById('application.title').execute()).data
      const projectResponse = (await service.getById('application.project').execute()).data

      this.applicationTitle = titleResponse.value as string
      this.applicationProject = projectResponse.value as string
    } catch (e) {
      console.error(e)
    }

  }
}


