import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'
import { VSelect, VDivider, VListItem, VListItemTitle, VListItemContent, VBtn } from "vuetify/lib"

Vue.use(Vuetify, { 
  components: { VSelect, VDivider, VListItem, VListItemTitle, VListItemContent, VBtn }
});

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  }
});
